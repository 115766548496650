<template>
  <div class="wrapper">
    <el-menu class="main-sidebar" default-active="0" :collapse="sidebarIsCollapse">
      <el-space :size="0">
        <el-button class="margin-1" icon="el-icon-s-unfold" circle v-if="sidebarIsCollapse"
                   @click="toggleSidebar"></el-button>
        <el-button class="margin-1" icon="el-icon-s-fold" circle v-else @click="toggleSidebar"></el-button>
        <div v-if="currentUser && !sidebarIsCollapse" class="name-container">
          <strong>{{ headerName }}</strong>
          <el-avatar :src="imgSrc" shape="square" scale-down></el-avatar>
        </div>
      </el-space>

      <el-menu-item index="1" @click="goTo('dashboardIndex')">
        <i class="el-icon-s-home"></i>
        <span>Home</span>
      </el-menu-item>
      <el-menu-item index="2" @click="$router.push('/dashboard/appartamento/'+getUserApartmentId())" v-if="currentUser && hasAps()">
        <i class="el-icon-school"></i>
        <span>Appartamento</span>
      </el-menu-item>
      <el-menu-item disabled index="2" v-else-if="currentUser && currentUser.type!=='admin'">
        <i class="el-icon-school"></i>
        <span>Appartamento</span>
      </el-menu-item>
      <el-menu-item ref="readingsButton" index="3" @click="$router.push('/dashboard/letture/'+getUserApartmentId())" v-if="currentUser && hasAps()">
        <i class="el-icon-data-line"></i>
        <span>Letture</span>
      </el-menu-item>
      <el-menu-item disabled index="3" v-else-if="currentUser && currentUser.type!=='admin'">
        <i class="el-icon-data-line"></i>
        <span>Letture</span>
      </el-menu-item>
      <el-menu-item index="4" @click="goTo('utenti')" v-if="isGranted('ROLE_MANAGER')">
        <i class="el-icon-user"></i>
        <span>Utenti</span>
      </el-menu-item>
      <el-menu-item index="5" @click="goTo('condomini')" v-if="hasCondo()">
        <i class="el-icon-office-building"></i>
        <span>Condomini</span>
      </el-menu-item>
      <el-menu-item index="6" @click="goTo('dataimport')" v-if="isGranted('ROLE_MANAGER')">
        <i class="el-icon-document-add"></i>
        <span>Data Import</span>
      </el-menu-item>
      <el-menu-item index="7" @click="goTo('condominiumimport')" v-if="isGranted('ROLE_MANAGER')">
        <i class="el-icon-office-building"></i>
        <span>Importa Condominio</span>
      </el-menu-item>

    </el-menu>

    <div v-if="!sidebarIsCollapse" class="sidebar-footer">
      <div class="owner-info" v-if="!sidebarIsCollapse">
        <el-avatar :src="owner.imgSrc" :size="100" shape="square" fit="contain"></el-avatar>
        <h3 class="no-margin"> {{owner.name}} </h3>
        <p class="no-margin"> {{owner.address}} </p>
        <p class="no-margin"> {{owner.city}} </p>
        <p class="no-margin"> {{owner.tel}} </p>
      </div>
    </div>

  </div>


</template>

<script>
import {mapActions, mapGetters} from "vuex";
import UserMixin from "../mixins/UserMixin";

export default ({
  name: 'dashboard',
  mixins: [UserMixin],
  data() {
    return {
      imgSrc: "",
      headerName: "",
      owner: {
        imgSrc: "",
        name: "",
        address: "",
        tel: ""
      }
    };
  },
  computed: {
    ...mapGetters(["sidebarIsCollapse", "userFullname", "userRoles", "appUser", "currentUser", "isGranted"])
  },
  methods: {
    ...mapActions(["toggleSidebar", "fetchUser"]),
    goTo(name) {
      this.$router.push({name})
    },
    hasCondo(){
      return this.isGranted("ROLE_MANAGER") || (this.currentUser && this.currentUser.type === 'admin');
    },
    hasAps(){
      return this.currentUser.apartments.length
    },
    getUserApartmentId(){
      return this.currentUser.apartments[0].id;
    },
    setOwnerInfo(user){
      if (!user){
        this.owner.name = "ESD Servizi Globali"
        this.owner.imgSrc = "logo_default_sunny.PNG"
        this.owner.address = "Via C. E. Filiberto, 1"
        this.owner.city = "San Giusto Canavese (TO)"
        this.owner.tel = "+39 3204058840"
      } else {
        this.owner.name = this.getFullName(user);
        this.owner.imgSrc = user.imgData || "logo_default_sunny.PNG";
        this.owner.address = user.address.street + ", " + user.address.number
        this.owner.city = user.address.city + " (" + user.address.state +")";
        this.owner.tel = user.phoneNumber1
      }

    }
  },
  async created() {
    await this.fetchUser(this.appUser.id);

    this.imgSrc = "logo_default_sunny.PNG";
    this.headerName = "Sunny";

    if (this.isGranted("ROLE_MANAGER")) {
      this.setOwnerInfo(null)
    } else if (this.currentUser.type === 'admin') {
      this.setOwnerInfo(this.currentUser)
    } else {
      const imgSource = this.currentUser.apartments[0].condominium.imgSource;
      if (imgSource && imgSource!=="default"){
        let owner = this.currentUser.apartments[0].condominium[imgSource];
        this.setOwnerInfo(owner)
      } else {
        this.setOwnerInfo(null)
      }
    }
  }
})
</script>

<style scoped>

.wrapper{
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-right: 1px solid #e6e6e6;
}

.main-sidebar:not(.el-menu--collapse) {
  width: 300px;
}

.main-sidebar {

}

.margin-1 {
  margin: 10px;
}

.username {
  margin: 0
}

.name-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 225px;
}

.sidebar-footer{
  padding: 10px;
}

.owner-info{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0;
}

.no-margin{
  margin: 0;
}


</style>
